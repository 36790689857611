<template>
  <v-row class="pt-0 mt-0">
    <SidePage />
    <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="pa-5 pa-sm-10">
          <v-row justify="center">
            <v-col cols="12" md="12" lg="12" xl="12">
              <img src="@/assets/images/logos/logo-comillas.png" width="40" />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Sign in</h2>

              <v-form 
                ref="form" 
                v-model="valid" 
                lazy-validation
              >
                <v-text-field
                  v-model="form.email"
                  :rules="emailRules"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined
                  autocomplete="email"
                ></v-text-field>

                <v-text-field
                  autocomplete="password"
                  v-model="form.password"
                  @keydown.enter="btnClick"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  label="Password"
                  name="Password"
                  counter
                  :rules="passwordRules"
                  outlined
                  required
                ></v-text-field>                
                
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  class="mr-4 my-2"
                  block
                  submit
                  @click="btnClick"
                >
                  Sign in
                </v-btn>

              </v-form>

              <div class="d-block align-center mb-4 mt-3 mb-sm-0">                  
                <div class="ml-auto d-flex justify-space-between">
                  <div>
                    <small>Don't have an account?</small>
                    <router-link :to="{ name: 'SignUp' }" class="ml-1">Sign up</router-link>
                  </div>
                   
                  <router-link 
                    :to="{ name: 'ForgotPassword' }" 
                  >
                    Forgot password?
                  </router-link>
                </div>
              </div>
              
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SidePage from './SidePage.vue'
import axios from 'axios';

export default {
  name: "SignIn",
  components:{
    SidePage
  },
  data: () => ({
    valid: true,
    loader: null,
    loading: false,
    form: {
      email: "",
      password: "",      
    },    
    show1: false,
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],    
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length > 4) || "Password must be more than 4 characters long",
      v => (v && v.length <= 26) || "Password must be less than 27 characters long"
    ],    
  }),
  methods: {
    validate () {
      const result = this.$refs.form.validate()
      return result;
    },
    async submit() {
      if(!this.validate()) return;

      try {
        const {data} = await axios.post('api/users/signin', this.form);
    
        this.$store.dispatch('SAVETOKEN', data)        
        // this.$router.push({name: 'Analytical'})
        
        this.reset();
        this.resetValidation()
      } catch ({message}) {  
              
        this.$alertify.error(message)
        this.valid = true;        
      }
      this.loader = null
    },
    btnClick(){
      this.loader = 'loading'
      const l = this.loader
      this[l] = !this[l]
      this.valid = false;

      setTimeout(() => { 
        if(this.validate()){                
          this.submit()              
        }          
        (this[l] = false)
          
      }, 2000)
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    reset () {
      this.$refs.form.reset()
    },
  }
};
</script>
