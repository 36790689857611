import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

import SignIn from "@/views/authentication/SignIn"
import SignUpEmployee from "@/views/authentication/SignUp"
import SignUpAdmin from "@/views/authentication/SignUpAdmin"
import VerifyAccount from "@/views/authentication/VerifyAccount"
import ForgotPassword from "@/views/authentication/ForgotPassword"
import SetNewPassword from "@/views/authentication/SetNewPassword"
import fileUpload from "@/views/upload/file-upload"

import state from '../store/auth.module'
import store from '../store/index'

Vue.use(Router);

const RedirectIfAuthenticated = (to, from, next) => {
  const user = state.state.user; 

  if (user) { 
    if (user.role == 'Staff' ) {  
      if (to.name == 'Chat') return next();

      return next({name: 'Chat'});

    } else if(user.role == 'Assistant') {
      if (to.name == 'Chat') return next();
      if (to.name == 'Files') return next();

      return next({name: 'Chat'});
    }  else {
      return next({name: 'Analytical'});
    }
  } else {
    return next();  
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      name: "SignIn",
      path: "/signIn",
      component: SignIn,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      },
    },    
    {
      name: "SignUp",
      path: "/signup",
      component: SignUpEmployee,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: "Signup-admin",
      path: "/signup-admin",
      component: SignUpAdmin,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: "ForgotPassword",
      path: "/forgot-password/",
      component: ForgotPassword,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: "VerifyAccount",
      path: "/verify-account/:code",
      component: VerifyAccount,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: "SetNewPassword",
      path: "/set-new-password/:code",
      component: SetNewPassword,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: "file-upload",
      path: "/upload/:code?",
      component: fileUpload,
      beforeEnter: RedirectIfAuthenticated,
      meta: {
        all: true
      }
    },
    {
      name: 'Home',
      path: "/",   
      component: () => import("@/layouts/full-layout/Layout"),               
      children: [
        {
          name: "Analytical",
          path: "/",          
          meta: {
            super_user: true,
            Admin: true, 
          },          
          component: () => import("@/views/dashboards/analytical/Analytical"),
        }, 
        {
          name: "Billing",
          path: "billing",          
          meta: {
            super_user: true,
            Admin: true, 
          },          
          component: () => import("@/views/billing/Billing"),
        },  
        // Companies 
        {
          name: "Companies",
          path: "office/companies",
          meta: {
            super_user: true,
            Admin: true, 
            // Assistant: true,
          },
          component: () => import("@/views/office/companies/Companies"),
        },    
        {
          name: "Users",
          path: "office/users",
          meta: {
            super_user: true,
            Admin: true, 
            // Assistant: true,
          },
          component: () => import("@/views/office/users/Users"),
        },  
        {
          name: "Files",
          path: "office/files",
          meta: {
            super_user: true,
            Admin: true, 
            Assistant: true,
          },
          component: () => import("@/views/office/files/Files"),
        }, 
        {
          name: "managementCompanies",
          path: "management/companies",
          meta: {
            super_user: true,
          },
          component: () => import("@/views/management/companies/Companies"),
        }, 
        {
          name: "managementUsers",
          path: "management/users",
          meta: {
            super_user: true,
          },
          component: () => import("@/views/management/users/Users"),
        }, 
        {
          name: "Chat",
          path: "chat",
          meta: {
            super_user: true,
            Admin: true, 
            Assistant: true,
            Staff: true,
          },
          component: () => import("@/views/chat/Chat"),
        },
        {
          name: "Profile",
          path: "profile",
          meta: {
            super_user: true,
            Admin: true, 
            Assistant: true,
            Staff: true,
          },
          component: () => import("@/views/profile/Profile"),
        },          
        
      ]
    }  
  ],
});

router.beforeEach((to, from, next) => {
  const user = state.state.user;

  if (to.matched.some(record => record.meta.all)) { 
    next(
      vm => {     
        vm.preRoute = from        
      }
    )    
  } else if(user) {                 
    if(user.role == 'Admin'){
      if (to.matched.some(record => record.meta.Admin)){
        next(
          vm => {
            vm.prevRoute = from
          }
        )
      } else {
        next({name: 'Analytical'})
      }
    }else if(user.role == 'Assistant'){
      if (to.matched.some(record => record.meta.Assistant)){
        next(
          vm => {
            vm.prevRoute = from
          }
        )
      } else {
        next({name: 'Chat'})
      }
    } else if(user.role == 'Staff'){
      if (to.matched.some(record => record.meta.Staff)){
        next(
          vm => {
            vm.prevRoute = from
          }
        )
      } else {
        next({name: 'Chat'})
      }
    } else if(user.role == 'super_user'){
      if (to.matched.some(record => record.meta.super_user)){
        next(
          vm => {
            vm.prevRoute = from
          }
        )
      } else {
        next({name: 'Analytical'})
      }
    }
  } else {
    store.dispatch('LOGOUT')
  }
})

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
